/* AppHeader.css */

.header {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  right: 0;
  height: 64px; /* Höhe des Headers */
  display: flex;
  justify-content: center; /* Zentriert den Inhalt horizontal */
  align-items: center;
  padding: 0 50px; /* Erhöhtes Padding für mehr Platz */
  background-color: #D51067;
  box-sizing: border-box;
}

.logo {
  color: white;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer; /* Cursor als Zeiger für Logo */
  white-space: nowrap; /* Verhindert das Umbrechen der Wörter */
  text-align: center;
}

.link {
  color: white;
  font-size: 15px; /* Gleiche Schriftgröße wie das Logo */
  font-weight: bold; /* Gleiche Schriftstärke wie das Logo */
  cursor: pointer; /* Cursor als Zeiger für den Link */
  white-space: nowrap; /* Verhindert das Umbrechen der Wörter */
  position: absolute; /* Absolute Positionierung */
  right: 50px; /* Positioniert den Link am rechten Rand */
}

/* Responsive Styles für kleine Bildschirme */
@media (max-width: 600px) {
  .header {
    padding: 0 20px; /* Reduziertes Padding für kleinere Bildschirme */
    height: 64px; /* Beibehaltung der Höhe */
  }

  .logo {
    font-size: 20px; /* Größere Schriftgröße für bessere Lesbarkeit */
  }

  .link {
    font-size: 16px; /* Leicht größere Schriftgröße */
    right: 20px; /* Angepasstes Positionieren für kleinere Padding */
  }
}
